import esProductListCountQuery from '~layers/app/graphql/plp/esProductListCountQuery.gql'

const applyOptionsDirectly = ref(false)

const rawFilters = ref<any[]>([])

const filters = computed(() => {
  return rawFilters.value?.filter(filter => !!filter && !filter.isHidden)?.filter(filter => !(filter.options && !filter.options.length))?.map((filter) => {
    const _filter = {
      ...filter,
    }

    _filter.activeOptionsCount = 0
    _filter.label = _filter.label.charAt(0).toUpperCase() + _filter.label.slice(1)

    if (_filter.options?.length) {
      _filter.options = _filter.options.map((option: any) => {
        return {
          ...option,
          selected: option.active,
        }
      })

      _filter.activeOptionsCount = _filter.options.filter((option: any) => option.active)?.length || 0
    }

    if (_filter.type === 'range') {
      _filter.minSelected = _filter.minSelected ?? _filter.min
      _filter.maxSelected = _filter.maxSelected ?? _filter.max
      _filter.minToBeSelected = _filter.minToBeSelected ?? _filter.minSelected
      _filter.maxToBeSelected = _filter.maxToBeSelected ?? _filter.maxSelected
      _filter.activeOptionsCount += Math.floor(_filter.min) !== Math.floor(_filter.minSelected) ? 1 : 0
      _filter.activeOptionsCount += Math.ceil(_filter.max) !== Math.ceil(_filter.maxSelected) ? 1 : 0
    }

    _filter.isExpanded = _filter.isExpanded || _filter.activeOptionsCount > 0

    return _filter
  })?.sort((a: any, b: any) => {
    return a.position - b.position
  }) || []
})

const activeFilters = computed(() => {
  return [
    ...filters.value,
  ].filter(filter => filter.activeOptionsCount > 0).map((f) => {
    const filter = { ...f }

    delete filter.position
    delete filter.hasUrl
    delete filter.isHidden
    delete filter.isExpanded
    delete filter.numberUnfoldedOptions
    delete filter.limitOptionsShowSearchBox
    delete filter.isShowSearchBox

    if (filter.type === 'range') {
      if (filter.min === filter.minSelected) {
        delete filter.minSelected
      }
      delete filter.min
      delete filter.minToBeSelected

      if (filter.max === filter.maxSelected) {
        delete filter.maxSelected
      }
      delete filter.max
      delete filter.maxToBeSelected
    }

    if (filter.options?.length) {
      const options = [
        ...filter.options,
      ].filter(option => option.active)

      filter.options = options
    }

    return filter
  })
})

const activeFiltersCount = computed(() => {
  return activeFilters.value.length || 0
})

const expectedTotal = ref(0)

export default (meta) => {
  const route = useRoute()
  const router = useRouter()
  const { search } = useCatalogSearch()
  const { defaultSort, sort, defaultItemsPerPage, itemsPerPage, totalCount } = useCatalogPaginator()

  expectedTotal.value = totalCount.value

  const filtersFromRoute = computed(() => {
    const params: any[] = [
      ...(meta.value?.filters?.map((filter: EsUrlFilterValue) => {
        const { __typename, ...remainingFilterData } = filter
        return remainingFilterData
      }) || []),
    ]

    Object.entries(route.query).forEach(([filterKey, filterValue]) => {
      // console.log('filterKey', filterKey)
      // console.log('filterValue', filterValue)

      if (!filterValue) {
        return
      }

      if (/^(gclid|dclid|gad_source|ref|session_id|cx|ie|cof|siteurl|zanpid|origin|fbclid|mc_[a-z]+|utm_[a-z]+|_bta_[a-z]+|mpid|profile(source|id)|pk_campaign|piwik_campaign|pk_kwd|piwik_kwd|pk_keyword|pixelId|kwid|kw|adid|chl|dv|nk|pa|camid|adgid|qit(p|q|c|e|rt|h){1})$/.test(filterKey)) {
        return
      }

      const filterValues = filterValue.split('~').map((filterValue: string) => decodeURIComponent(filterValue))
      const filter = {
        key: filterKey,
        values: filterValues,
      }
      if (filterValues.length === 2 && filterValues.every((val: string) => !isNaN(+val))) {
        filter.min = parseFloat(filterValues[0])
        filter.max = parseFloat(filterValues[1])
      }
      params.push(filter)
    })

    return Object.values(params.reduce((acc: any, item: any) => {
      acc[item.key] = acc[item.key] === undefined
        ? item
        : {
            ...acc[item.key],
            ...item,
            values: [
              ...acc[item.key].values,
              ...item.values,
            ],
          }
      return acc
    }, {}))
  })

  function generateUrlFromSelectedFilters() {
    const basePathPieces = route?.path?.replace(/^\/|\/$/g, '')?.split('/') || []
    const lastBasePathPiece = basePathPieces[basePathPieces.length - 1]
    const params = new URLSearchParams()

    let setFilter = false
    params.set('filter', '1')

    if (search.value) {
      params.set('q', search.value)
    }

    filters.value.forEach((filter: any) => {
      let selectedOptions: any = []

      switch (filter.type) {
        case 'range':
          if ((filter.minSelected && filter.minSelected !== filter.min) || (filter.maxSelected && filter.maxSelected !== filter.max)) {
            params.set(filter.key, [filter.minSelected, filter.maxSelected].join('~'))
            setFilter = true
          }
          break
        default:
          selectedOptions = filter.options?.filter((option: any) => option.active)

          if (selectedOptions?.length) {
            params.set(filter.key, selectedOptions.map((option: any) => encodeURIComponent(option.key)).join('~'))
            setFilter = true
          }

          // Check if filter exists in url path and unset if it's not selected anymore
          if (!selectedOptions?.length && filter.key !== 'category') {
            const urlFilter = meta.value?.filters?.find((urlFilter: any) => urlFilter.key === filter.key)

            if (urlFilter && filter.options?.find((option: any) => option.urlPath === lastBasePathPiece)) {
              basePathPieces.pop()
            }
          }

          break
      }
    })

    if (!setFilter || search.value) {
      params.delete('filter')
    }

    if (sort.value !== defaultSort) {
      params.set('sort', sort.value)
    }

    if (itemsPerPage.value !== defaultItemsPerPage) {
      params.set('limit', itemsPerPage.value)
    }

    const basePath = '/' + basePathPieces.join('/') + '/'
    const url = basePath + (params.toString().length > 0 ? '?' + params.toString() : '')

    return url
  }

  function optionToggle(filterKey: string, optionKey: string) {
    // console.log('optionToggle', filterKey, optionKey)

    // Direct filters manipulation for reactivity
    const filter: any | undefined = filters.value.find((filter: any) => filter.key === filterKey)
    const option: any | undefined = filter?.options?.find((option: any) => option.key === optionKey)

    if (option) {
      option.selected = !option.selected
    }

    if (applyOptionsDirectly.value) {
      filterApply(filterKey)
    }
  }

  function filterRangeResetMin(filterKey: string) {
    const filter: any | undefined = filters.value.find((filter: any) => filter.key === filterKey)

    if (filter) {
      filter.minSelected = filter.min
      filter.minToBeSelected = filter.min
    }
  }

  function filterRangeResetMax(filterKey: string) {
    const filter: any | undefined = filters.value.find((filter: any) => filter.key === filterKey)

    if (filter) {
      filter.maxSelected = filter.max
      filter.maxToBeSelected = filter.max
    }
  }

  // Single Filter Level
  function filterApply(filterKey: string) {
    // console.log('filterApply filterKey', filterKey)
    // Direct filters manipulation for reactivity
    const filter: any | undefined = filters.value.find((filter: any) => filter.key === filterKey)

    if (filter.type === 'range') {
      filter.minSelected = filter.minToBeSelected
      filter.maxSelected = filter.maxToBeSelected
    }
    else if (filter.options?.length) {
      filter.options.forEach((option: any) => {
        option.active = option.selected
      })
    }

    const url = generateUrlFromSelectedFilters()
    router.push(url)
  }

  function filterReset(filterKey: string) {
    // Direct filters manipulation for reactivity
    const filter: EsFilterExtended | undefined = filters.value.find((filter: EsFilterExtended) => filter.key === filterKey)

    if (filter.type === 'range') {
      // Step back
      filter.minToBeSelected = filter.minSelected || filter.min
      filter.maxToBeSelected = filter.maxSelected || filter.max
    }
    else if (filter.options?.length) {
      filter.options.forEach((option: EsFilterOptionExtened) => {
        // Step back
        option.selected = option.active
      })
    }
  }

  function filterRemove(filterKey: string) {
    // Direct filters manipulation for reactivity
    const filter: EsFilterExtended | undefined = filters.value.find((filter: EsFilterExtended) => filter.key === filterKey)

    if (filter.type === 'range') {
      // Clear options
      filter.minToBeSelected = filter.min
      filter.maxToBeSelected = filter.max
    }
    else if (filter.options?.length) {
      filter.options.forEach((option: EsFilterOptionExtened) => {
        option.selected = false
      })
    }
  }

  // All Filters Methods
  function filtersResetAll() {
    filters.value.forEach((filter: any) => {
      if (filter.type === 'range') {
        filter.minToBeSelected = filter.minSelected || filter.min
        filter.maxToBeSelected = filter.maxSelected || filter.max
      }
      else if (filter?.options?.length) {
        filter.options.forEach((option: any) => {
          option.selected = option.active
        })
      }
    })
  }

  function filtersRemoveAll() {
    filters.value.forEach((filter: any) => {
      if (filter.type === 'range') {
        filter.minToBeSelected = filter.min
        filter.maxToBeSelected = filter.max
      }
      else if (filter?.options?.length) {
        filter.options = filter.options.forEach((option: any) => {
          // if (option.selected) {
          // console.log('reset', filter.key, option.key, 'to false')
          // }
          option.selected = false
        })
      }

      // console.log('filtersRemoveAll', filter.key, filter)
    })
  }

  function filtersApplyAll() {
    filters.value.forEach((filter: any) => {
      if (filter.type === 'range') {
        filter.minSelected = filter.minToBeSelected
        filter.maxSelected = filter.maxToBeSelected
      }
      else if (filter?.options?.length) {
        filter.options.forEach((option: any) => {
          option.active = option.selected
        })
      }

      // console.log('filtersApplyAll', filter.key, filter)
    })

    const url = generateUrlFromSelectedFilters()
    // console.log('url', url)
    router.push(url)
  }

  // Expecteed Result
  async function getExpectedTotal() {
    const lclFilters = [
      ...(meta.value?.filters || []),
    ]

    filters.value.forEach((filter: any) => {
      switch (filter.type) {
        case 'range':
          if ((filter.minToBeSelected && filter.minToBeSelected !== filter.min) || (filter.maxToBeSelected && filter.maxToBeSelected !== filter.max)) {
            lclFilters.push({
              key: filter.key,
              min: parseFloat(filter.minToBeSelected),
              max: parseFloat(filter.maxToBeSelected),
            })
          }
          break
        default:
          if (filter?.options?.length) {
            const selectedOptions = filter.options.filter((option: any) => option.selected).map((option: any) => option.key)

            if (selectedOptions.length) {
              lclFilters.push({
                key: filter.key,
                values: selectedOptions,
              })
            }
          }
          break
      }
    })

    const variables = {
      filters: Object.values(Object.assign({}, ...lclFilters.map((f) => {
        const filter = {
          ...f,
        }

        if (filter.__typename) {
          delete filter.__typename
        }

        return { [filter.key]: filter }
      },
      ))),
      search: search.value,
    }

    const response = await useAsyncQuery<Query>(esProductListCountQuery, variables)
    expectedTotal.value = response.data.value?.esProductList?.totalCount || 0
    return response.data.value?.esProductList?.totalCount
  }

  return {
    generateUrlFromSelectedFilters,
    applyOptionsDirectly,

    // Filter Options Level
    optionToggle,
    filterRangeResetMin,
    filterRangeResetMax,

    // Single Filter Level
    filterApply,
    filterReset,
    filterRemove,

    // All Filters Level
    rawFilters,
    filters,
    filtersApplyAll,
    filtersResetAll,
    filtersRemoveAll,
    filtersFromRoute,
    activeFilters,
    activeFiltersCount,
    expectedTotal,
    getExpectedTotal,
  }
}
